let domain_text = "Realopinie.pl"
export const msg = {
    "product_search": "czego szukasz?",

    "home_page_title": domain_text + " - wszystkie recenzje produktów w Internecie",
    "home_page_desc": "Wszystkie recenzje produktów w internecie znajdziesz na " + domain_text,
    "home_page_canonical": "https://realopinie.pl",
    "home_page_landing_title": "Wszystkie recenzje w jednym miejscu",
    "home_page_landing_desc": "<b>" + domain_text + "</b> analizuje miliony recenzji produktów z setek serwisów internetowych i pokazuje Ci te najlepsze.",


    "user_review_appendix": "opinii",
    "user_review_appendix_1": "opinia",
    "user_review_appendix_2_3_4": "opinie",
    "user_review_expansian_panel_appendix": "- Opinie",
    "read_reviews_button_text": "Czytaj",
    "infinite_loop_loading": "Ładowanie...",
    "infinite_loop_end": "Koniec.",
    "price_with_currency": "x_price_x zł",

    "expansion_details_text": domain_text + " przeskanował internet w poszukiwaniu <b>opinii na temat produktu o nazwie x_title_x</b>. Na tej stronie znajdziesz wszystkie <b>opinie i oceny dotyczące produktu x_title_x.</b>",

    "product_page_title_appendix": "Opinie - " + domain_text,
    "product_page_desc": "x_title_x opinie, x_title_x recenzje, oceny x_title_x",

    "why_good": "Pozytywne",
    "why_bad": "Negatywne",
    "read_more": "jeszcze",
    "more": "jeszcze",
    "review": "opinii",
    "review_1": "opinia",
    "review_2_3_4": "opinie",
    "similar_items": "Produkty rekomendowane",
    "list_bests_in_the_cat": "Wyświetl listę najlepszych w tej kategorii!",
    "read_before_buying": "Przeczytaj informacje o tym produkcie! ",
    "no_review": "Bu ürün için yorum bulunmamaktadır. Aşağıdan bu kategorideki diğer ürünlere göz atabilirsiniz.",

    "terms_and_cond": "Warunki usługi",
    "privacy_policy": "Polityka prywatności",

    "categories": "Kategorie",
    "back": "Cofnij",
    "home": "Realopinie",
    "most_review": "Po liczbie komentarzy",
    "most_rated": "Po ocenach klientów",
    "most_expensive": "Po najwyższej cenie",
    "most_cheap": "Po najniższej cenie",
    "read_about_cat": "Learn everything about these products!",

    "product_page": {
        "info": "Informacje",
        "go_to_comments": "Przeczytaj opinie.",
        "analyse": "Analiza",
        "general_stats": "Dla produktu <b>x_title_x</b> liczba opinii klientów wynosi <b>x_reviewcount_x</b>, liczba witryn handlu elektronicznego, z których je pobrano, równa się <b>x_sitecount_x</b>, a średnia ocena to <b>x_avgstar_x</b>.",
        "stats_by_sellers": "Szczegółowe statystyki sprzedawcy(-ów):",
        "seller_stats": "Na stronie <b>x_sitename_x</b> liczba opinii klientów wynosi <b>x_reviewcount_x</b>, a średni wynik to <b>x_avgstar_x</b>.",
        "go_to_seller": "Przejdź do tego sprzedawcy.",
        "detail": "Szczegół",
        "go_to_category": "Kliknij, aby wyświetlić listę wszystkich produktów w tej kategorii.",
        "go_to_article": "Kliknij, aby dowiedzieć się wszystkiego o tym produkcie.",
        "recommended_seller": "Polecany sprzedawca",
        "go_to_recommended_seller": "Idź do sklepu",
        "bests_in_this_cat": "Wyświetl listę najlepszych w tej kategorii!",
        "buyers_guide": "Buyer's Guide",
    },
    "article": {
        "content_text": "Table Of Contents",
        "related_articles": "Similar Articles",
        "show_items_in_cat": "List All Products",
        "dynamic_section_title": "ReviewBucket's x_keyword_x Analysis",
        "dynamic_section_summary": `ReviewBucket scanned all the E-Commerce sites for x_keyword_x and analyzed the users' reviews about it.
                                    According to our AI algorithm's analysis results, we extracted 2 products for your interest. 
                                    The first one is the best product in terms of price-performance ratio and 
                                    the second one is the best product in terms of quality. You can see these products below.`,

        "dynamic_section_fp_desc": `<b>For those who looking for the best x_keyword_x in terms of price-performance ratio</b>, we highly recommend
                                    <a href=\"/x_product_link_x\"> x_product_name_x</a>.
                                    According to our results, <b>x_review_count_x</b> users reviewed <b>x_product_name_x</b> 
                                    and the average score given by the users for this product is <b>x_avg_star_x</b> out of 5.
                                    Considering its cheap price and the analysis results of its users' reviews, we can easily say that 
                                    this product has the best price-performance ratio.`,

        "dynamic_section_bp_desc": `ReviewBucket AI technology chosen the <a href=\"/x_product_link_x\"> x_product_name_x</a>
                                    for those who want to buy the best x_keyword_x in terms of quality.
                                    According to our results, <b>x_review_count_x</b> users reviewed <b>x_product_name_x</b> 
                                    and the average score given by the users for this product is <b>x_avg_star_x</b> out of 5.
                                    Although it is a bit expensive compared to its alternatives,
                                    the users say that it is more durable and qualified than the others.`,

        "items_in_cat_text": `<a href=\"/x_cat_link_x\">Here are the other products in this category along with their users' reviews;</a>`,

    },

    "search_page": {
        "info": "Znaleziono (<b>x_res_count_x</b>) produktów dla zapytania: „<b>x_search_term_x</b>”"
    }
}